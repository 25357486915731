interface ErrorProps {
    error: string|null;
}

export default function Error({error}: ErrorProps) {
    return error !== null ? (
        <span style={{color: "red", fontWeight: "bold"}}>
            {error}<br />
            Please try again shortly, or get in contact if the issue persists.
        </span>
    ) : (<span></span>)
}