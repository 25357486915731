import { useEffect, useState } from "react";
import Invitee from "../../interfaces/Invitee";
import InviteeMap from "../../interfaces/InviteeMap";
import LoginData from "../../interfaces/LoginData";
import Error from "../Error/Error";
import InviteeTab from "../InviteeTab/InviteeTab";
import LoggedInWelcomeTab from "../LoggedInWelcomeTab/LoggedInWelcomeTab";

interface InviteeTabManagerProps {
    invitees: InviteeMap;
    onInviteeSave: (inviteeId: string, data: Invitee) => Promise<void>;
    loginData: LoginData;
    error: string|null;
}

export default function InviteeTabManager({invitees, loginData, onInviteeSave, error}: InviteeTabManagerProps) {

    const [selectedInviteeId, setSelectedInviteeId] = useState<string|null>(null);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);

    const windowUnloadEvent = (ev: any) => {
        if (unsavedChanges) {
            ev.preventDefault();
            ev.returnValue = 'You have unsaved changes. Are you sure you wish to leave?';
        }
    };

    useEffect(() => {
        window.addEventListener("beforeunload", windowUnloadEvent);
    }, []);

    const selectTab = (inviteeId: string) => {
        if (unsavedChanges) {
            window.alert("There are unsaved changes to the current invitee. Please save them before changing invitee");
        } else {
            setSelectedInviteeId(inviteeId);
            setSaved(false);
        }
    };

    const onInviteeDataChange = (inviteeId: string) => {
        setUnsavedChanges(true);
        setSaved(false);
    };

    const onInviteeDataRevert = () => {
        setUnsavedChanges(false);
    };

    const onInviteeSaveTabManager = (inviteeId: string, data: Invitee) => {
        setUnsavedChanges(false);
        onInviteeSave(inviteeId, data).then(() => {
            setSaved(true);
        })
    };

    return (
        <div className="invitee-tab-manager">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className={"nav-link " + (selectedInviteeId === null ? "active" : "")}
                            onClick={() => {setSelectedInviteeId(null)}}
                            aria-current="page" href="#">
                        Info
                    </a>
                </li>

                {
                    Object.keys(invitees).map((inviteeId) => (
                        <li key={inviteeId} className="nav-item">
                            <a className={"nav-link " + (selectedInviteeId == inviteeId ? "active" : "")}
                                // onclick, update the selected invitee to the selected invitee
                                onClick={() => {selectTab(inviteeId)}}
                                aria-current="page" href="#">
                                    {invitees[inviteeId].full_name}
                                    {
                                        (unsavedChanges && inviteeId == selectedInviteeId) ?
                                        (
                                            <i className="fa fa-floppy-o"> (Unsaved)</i>
                                        ) : (
                                            invitees[inviteeId].status == "INCOMPLETE" ? (
                                                <i className="fa fa-bell"></i>
                                            ) : (<div></div>)
                                        )
                                    }
                            </a>
                        </li>
                    ))
                }
            </ul>
            { selectedInviteeId != null ? (
                <>
                    <InviteeTab onSave={onInviteeSaveTabManager} onDataRevert={onInviteeDataRevert} onDataChange={onInviteeDataChange} inviteeId={selectedInviteeId} inviteeData={invitees[selectedInviteeId]} />
                    { saved && error === null ? (
                        // If saved it true and there is not error, notify the
                        // user
                        <div>
                            Your response have been saved!
                            {/* If there are any invitees with a status of incomplete and are not the current invitee,
                                show remdider to update them  */}
                            {Object.keys(invitees).map((inviteeId) => invitees[inviteeId].status === "INCOMPLETE" && inviteeId !== selectedInviteeId).indexOf(true) !== -1 ? (<div>
                                There is another invitee in your party with unanswered questions - don't forget to update them (see tabs at the top)
                            </div>) : (<span></span>)}</div>
                    ) : (<div></div>)}

                    <Error error={error} />

                    <br />Note: You can return to this website to change any preferences until December 1st
                </>
            ) : (
                <>
                    <LoggedInWelcomeTab loginData={loginData} invitees={Object.values(invitees)} />
                    <Error error={error} />
                </>
            )}
        </div>
    )
}
