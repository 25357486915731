import { useState } from "react";
import Error from "../Error/Error";

interface LoginProps {
    onLogin: (code: string, name: string) => void;
    invalidCode: boolean;
    error: string|null;
}

export default function Login({onLogin, invalidCode, error}: LoginProps) {

    const [codeValue, setCodeValue] = useState<string>("");
    const [nameValue, setNameValue] = useState<string>("");

    const onCodeChange = (ev: any) => {
        setCodeValue(ev.target.value);
    }

    const onNameChange = (ev: any) => {
        setNameValue(ev.target.value);
    }

    const onLoginButtonClick = () => {
        onLogin(codeValue, nameValue);
    }

    return (
        <div className="rsvp-text">
            Please enter the code from your wedding invite<br /><br />

            <div className="form-group">
                <input type="text" onChange={onCodeChange} value={codeValue} className="form-control" id="codeInput" placeholder="XXXXXXXX" /><br />
            </div>

            <br />
            Please enter one of the names of the invitees
            <div className="form-group">
                <input type="text" onChange={onNameChange} value={nameValue} className="form-control" id="nameInput" placeholder="John Smith" /><br />
            </div>

            {
                invalidCode ? (
                <div>
                    That code or name does not appear to be be correct.<br />
                    Please verify them and try again.
                </div>
                ) : (<div></div>)
            }

            <Error error={error} />

            <div className="rsvp-form">
                <button type="button" onClick={onLoginButtonClick} className="btn btn-light rsvp-button">Check Details</button>
            </div>
        </div>
    );
}