import logo from './logo.svg';
import './App.css';
import MainLayout from './components/MainLayout/MainLayout.tsx';
import MainContent from './components/MainContent/MainContent.tsx';

function App() {
  return (
    <div className="App">
      <MainLayout>
        <MainContent />
      </MainLayout>
    </div>
  );
}

export default App;
