import Question from "../../interfaces/Question";

interface QuestionFormProps {
    question: Question;
    onChange: (newValue: string) => void;
}

export default function QuestionForm({question, onChange}: QuestionFormProps) {
    const onSelectChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        onChange(ev.target.value);
    }

    return (
        <div className="mb-3">
            <h5>{question.title}</h5>
            <label htmlFor={"question" + question.id} className="form-label">{question.text}</label><br />
            <select className="form-select" id={"question" + question.id} onChange={onSelectChange} value={question.invitee_answer || ''}>
                <option value="">Please Select</option>
                {
                    question.answers.map((answer) => (
                        <option key={answer.id} value={answer.id}>{answer.text}</option>
                    ))
                }
            </select>
            <br /><br />
        </div>
    );
}
