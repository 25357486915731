
interface MainLayoutProps {
    children: string | JSX.Element | JSX.Element[]
}

export default function MainLayout({ children }: MainLayoutProps) {
    return (
        <div id="MainContent" className="row">
            <div className="col col-xs-12 col-sm-12 col-md-12 col-xl-6 col-xxl-6  offset-xl-3 offset-xxl-3 right-column main-column">
                <div className="column-right-image-left">
                    <div className="column-right-image-right">
                        <div className="rsvp-outer-card">
                            <div className="rsvp-inner-card">
                                <div className="rsvp-container">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}