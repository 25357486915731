import { useEffect, useState } from "react";
import Login from "../Login/Login";
import RsvpWelcomeDetails from "../RsvpWelcomeDetails/RsvpWelcomeDetails";
import LoginData from "../../interfaces/LoginData"
import InviteeMap from "../../interfaces/InviteeMap";
import InviteeTabManager from "../InviteeTabManager/InviteeTabManager";
import { stringify } from "querystring";
import Invitee from "../../interfaces/Invitee";


export default function MainContent() {

    const [clickedRsvp, setClickedRsvp] = useState<boolean>(false);
    const [loginData, setLoginData] = useState<LoginData|null>(null);
    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const [inviteeMap, setInviteeMap] = useState<InviteeMap>({});
    const [error, setError] = useState<string|null>(null);

    const handleRsvpButtonClick = () => {
        setClickedRsvp(true);
    }

    // When login data is updated, obtain information about all invitees
    const onInviteeIdUpdate = (): void => {
        if (loginData) {
            setError(null);
            loginData.inviteeIds.forEach((inviteeId: string) => {
                fetch(`/api/v1/invitee/${inviteeId}?code=${loginData.code}`).then(res => res.json()).then(data => {
                    if (data.Error) {
                        console.log(`Got error whilst retrieving data for invitee: ${inviteeId}`)
                    } else {
                        setInviteeMap((invitees) => Object.assign(
                            {}, 
                            invitees,
                            { [inviteeId]: data }
                        ));
                    }
                }).catch((error) => {
                    setError("There was an error loading your details.");
                });
            })
        }
    }
    useEffect(onInviteeIdUpdate, [loginData]);

    const onLogin = (code: string, name: string): void => {
        setError(null);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code: code, name: name })
        };
        fetch('/api/v1/login', requestOptions).then(res => res.json()).then(data => {
            if (data.Error) {
                setInvalidCode(true);
            } else {
                setLoginData({
                    code: code,
                    inviteeIds: data.invitees,
                    location: data.location,
                    googleMapsLink: data.google_maps_link,
                    arrivalText: data.arrival_text,
                    timingText: data.timing_text,
                });
                setInvalidCode(false);
            }
        }).catch(() => {
            setError("There was an error processing these details.");
        });
    }

    const onInviteeSave = (inviteeId: string, data: Invitee): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (loginData) {
                setError(null);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        code: loginData.code,
                        email_address: data.email_address,
                        additional_notes: data.additional_notes,
                        song_request: data.song_request,
                        questions: data.questions
                    })
                }
                fetch(`/api/v1/invitee/${inviteeId}`, requestOptions).then(res => res.json()).then(data => {
                    if (data.Error) {
                        console.log(`Got error whilst updating data for invitee: ${inviteeId}`)
                        setError("There was an error saving your preferences.");
                        reject();
                    } else {
                        setInviteeMap((invitees) => Object.assign(
                            {}, 
                            invitees,
                            { [inviteeId]: data }
                        ));
                        resolve();
                    }
                }).catch(() => {
                    setError("There was an error saving your preferences.");
                    reject();
                });
            }
        })
    };

    return loginData ? (
        <InviteeTabManager error={error} loginData={loginData} onInviteeSave={onInviteeSave} invitees={inviteeMap} />
    ) : (clickedRsvp ? (
        <Login error={error} invalidCode={invalidCode} onLogin={onLogin} />
    ) : (
        <RsvpWelcomeDetails onButtonClick={handleRsvpButtonClick} />
    ))
}
