import React, { useEffect, useState } from "react";
import Invitee from "../../interfaces/Invitee";
import Question from "../../interfaces/Question";
import QuestionForm from "../QuestionForm/QuestionForm";


interface InviteeTabProps {
    inviteeId: string;
    inviteeData: Invitee;
    onDataChange: (inviteeId: string) => void;
    onDataRevert: () => void;
    onSave: (inviteeId: string, data: Invitee) => void;
}

interface LocalQuestionData {
    [key: string]: Question;
}

export default function InviteeTab({inviteeId, inviteeData, onDataChange, onDataRevert, onSave}: InviteeTabProps) {

    const [localInviteeData, setLocalInviteeData] = useState<Invitee>(Object.assign({}, inviteeData));

    const [localQuestionData, setLocalQuestionData] = useState<LocalQuestionData>({});

    // On invitee data change, update fields from inbound data
    useEffect(() => {
        resetInviteeData();
    }, [inviteeId]);

    const resetInviteeData = () => {
        setLocalInviteeData(Object.assign({}, inviteeData));
        setLocalQuestionData(inviteeData.questions.reduce(function(map: {[key: string]: Question}, question: Question) {
            map[question.id] = question;
            return map;
        }, {}))
        onDataRevert();
    };

    const onEmailChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setLocalInviteeData((inviteeDataState) => {return Object.assign({}, inviteeDataState, {email_address: ev.target.value})});
        onDataChange(inviteeId);
    };

    const onAdditionalDetailsChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocalInviteeData((inviteeDataState) => {return Object.assign({}, inviteeDataState, {additional_notes: ev.target.value})});
        onDataChange(inviteeId);
    };

    const onSongRequestChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        setLocalInviteeData((inviteeDataState) => {return Object.assign({}, inviteeDataState, {song_request: ev.target.value})});
        onDataChange(inviteeId);
    };

    const onRevertChanges = () => {
        resetInviteeData();
    };

    const onSaveButtonClick = () => {
        // Emit save event, sending local invitee data,
        // overriding with local question data
        onSave(
            inviteeId,
            Object.assign({}, localInviteeData, {questions: Object.values(localQuestionData)})
        );
    };

    const updateQuestionAnswer = (questionId: string, newValue: string) => {
        // Update local question state, updating question's invitee_answer
        setLocalQuestionData((existingState) => Object.assign(
            {},
            existingState,
            {[questionId]: Object.assign({}, existingState[questionId], {invitee_answer: newValue})}
        ));
        onDataChange(inviteeId);
    }

    return (
        <div className="inviteeForm">
            <h1 className="welcomeTabTitle">{inviteeData.full_name}</h1>
            <h5>Email address</h5>
            <div className="mb-3">
                <label htmlFor="emailAddressInput" className="form-label">
                    We will send you ocassional updates when we have more information and questions
                </label>
                <input
                    required={true}
                    type="email" className="form-control" id="emailAddressInput"
                    onChange={onEmailChange}
                    value={localInviteeData?.email_address || ''} />
            </div>

            {
                Object.keys(localQuestionData).map((questionId) => (
                    <QuestionForm
                        key={questionId}
                        question={localQuestionData[questionId]}
                        onChange={(newValue) => {updateQuestionAnswer(questionId, newValue)}} />
                ))
            }

            <h5>Song Request</h5>
            <div className="mb-3">
                <label htmlFor="additionalInformationInput" className="form-label">What song would you love to hear? We'll create a combined list based on guest choices! (though we don't have room for everyone's)</label>
                <textarea
                    className="form-control" id="additionalInformationInput" rows={3}
                    onChange={onSongRequestChange}
                    value={localInviteeData?.song_request || ''}
                >
                </textarea>
            </div>

            <h5>Additional Information</h5>
            <div className="mb-3">
                <label htmlFor="additionalInformationInput" className="form-label">Additional information (dietary requirements/allergies etc.)</label>
                <textarea
                    className="form-control" id="additionalInformationInput" rows={3}
                    onChange={onAdditionalDetailsChange}
                    value={localInviteeData?.additional_notes || ''}
                >
                </textarea>
            </div>

            <div className="mb-3">
                <button style={{margin: "5px"}} className="btn btn-light saveButton" onClick={onSaveButtonClick} id="exampleFormControlTextarea1"><b>Save</b></button>
                <button style={{margin: "5px"}} className="btn btn-light saveButton" onClick={onRevertChanges} id="exampleFormControlTextarea1">Revert Changes</button>
            </div>
        </div>
    )
}
