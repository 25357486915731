
interface RsvpWelcomeDetailsProps {
    onButtonClick: () => void;
}

export default function RsvpWelcomeDetails({onButtonClick}: RsvpWelcomeDetailsProps) {
    return (
        <div className="rsvp-text">
            <div className="rsvp-title">
                The Wedding Of<br />
                Matt &amp; Courtney
            </div>
            <div className="rsvp-subtext">
                6th January 2024<br />
            </div>
            <div className="rsvp-form">
                <button onClick={onButtonClick} type="button" className="btn btn-light rsvp-button">RSVP</button>
            </div>
        </div>
    );
}