import { useEffect, useState } from "react";
import Invitee from "../../interfaces/Invitee";
import LoginData from "../../interfaces/LoginData";

interface LoggedInWelcomeTabProps {
    invitees: Invitee[];
    loginData: LoginData;
}

export default function LoggedInWelcomeTab({invitees, loginData}: LoggedInWelcomeTabProps) {

    const [nameString, setNameString] = useState<string>("");

    useEffect(() => {
        let nameStringBuilder = "";
        let inviteeNames = invitees.map((invitee) => invitee.full_name);
        for (let nameItx of inviteeNames) {
            let firstName = nameItx.split(" ")[0];

            // Handle first name
            if (nameStringBuilder === "") {
                nameStringBuilder = firstName;

            // Handle sub-sequent non-last names
            } else if (inviteeNames.indexOf(nameItx) !== (inviteeNames.length - 1)) {
                nameStringBuilder += `, ${firstName}`;
            } else {
                // Handle last name
                nameStringBuilder += ` and ${firstName}`;
            }
        }
        setNameString(nameStringBuilder);
    }, [invitees])

    return (
        <div className="inviteeForm">
            <h1 className="welcomeTabTitle">Welcome {nameString}</h1>
            <br />
            <b>Please use the above tabs to provide the RSVP information for each of the members of your party.</b><br />
            <br />

            <h2 className="welcomeTabTitle">
                Wedding Gifts
            </h2>

            Your presence with us is the greatest gift we could ask.
            However, if you wished to give us something, any money contribution towards our honeymoon would be very much appreciated.
            <br />
            <br />

            <h2 className="welcomeTabTitle">
                Times
            </h2>
            {loginData.arrivalText.split("\n").map((data) => <>{data}<br /></>)}
            <br />
            {loginData.timingText.split("\n").map((data) => <>{data}<br /></>)}
            <br />

            <h2 className="welcomeTabTitle">
                Location
            </h2>
            {loginData.location.split("\n").map((data) => <>{data}<br /></>)}
            <a href={loginData.googleMapsLink} target="_blank" rel="noopener noreferrer">Google Maps</a>
        </div>
    );
}